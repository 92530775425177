// Dependencies
import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// Hooks
import useWindowSize from '../hooks/useWindowSize'
import useSettings from '../hooks/useSettings'
// Components
import Clicker from '../components/clicker'
import Img from '../components/img'
import Layout from '../components/layout'
import Markdown from '../components/markdown'
import Slide, { useSlideInfo } from '../components/slide'

const DEFAULT_THEME = {
  textColour: '#FFFFFF',
  backgroundColour: '#000000'
};

const DEFAULT_SPEED = 10000;

const HomepageGallery = ({ showIntro, setCurrentColours, ...props }) => {

	// Refs
  const timer = useRef();

	// Content
	const projects = props.projects ? props.projects.filter(p => p.slides?.length > 0) : [];

	// UX
  const { height } = useWindowSize();
  const [currentIndex, setCurrentIndex] = useState(0);
	const [isLoading, setLoading] = useState(false);
  const [showClicker, setShowClicker] = useState(false);
  const counter = `${currentIndex + 1} of ${projects.length}`;
	const [mounted, setMounted] = useState(false)
	useEffect(() => {
		setMounted(true)
	}, [])

	// Content
	const { galleryInterval } = useSlideInfo(projects && projects[currentIndex])

	useEffect(() => {
		clearTimeout(timer.current)
		if (!showIntro) {
			timer.current = setTimeout(() => {
				next()
			}, galleryInterval)
		}
		return () => {
			clearTimeout(timer.current)
		}
	}, [showIntro, currentIndex, galleryInterval])

	// UI
  function next() {
    setCurrentIndex(prev => prev === projects.length - 1 ? 0 : prev + 1) // Loops back
  }

  function prev() {
    setCurrentIndex(prev => prev > 0 ? prev - 1 : projects.length - 1); // Loops round
  }

  const handleClick = (event) => {

    const isMobile = window.innerWidth < 768;

    if (isMobile) {
      // on mobile use outer edges
      if (event.clientX < (window.innerWidth * 0.25)) {
        prev()
      }
      if (event.clientX > (window.innerWidth * 0.75)) {
        next()
      }
    } else {
      // On desktop, use 50% of screen
      if (event.clientX < (window.innerWidth / 2)) {
        prev()
      } else {
        next()
      }
    }
  }


	const handlers = {
		onClick: handleClick,
		onMouseOver: () => setShowClicker(true),
		onMouseLeave: () => {
			setShowClicker(false)
		}
	}

  return (
    <div
			className={`homepage-gallery fs ${!showIntro ? 'show' : ''}`}
			{...handlers}
			style={{ height }}
		>
			{mounted && projects && projects.map((project, index) => (
				<Slide
					content={project}
					counter={counter}
					ready={!showIntro}
					isActive={index === currentIndex}
					isNext={(index === currentIndex + 1) || (index === currentIndex - 1)}
					setCurrentColours={setCurrentColours}
					setLoading={setLoading}
					key={`hpslide-${index}`}
				/>
			))}
			{showClicker && (
				<Clicker>
					{(isLeftSide) => {
						return (
							isLoading ? 'Loading' : isLeftSide ? 'Prev' : 'Next'
						)
					}}
				</Clicker>
			)}
		</div>
  )
}

const Homepage = ({ data }) => {

	const { tagline } = useSettings();
  const { projects, splash } = data.allContentfulHomepage.nodes[0];
  const [currentColours, setCurrentColours] = useState(DEFAULT_THEME);

  return (
    <Layout hasIntro splash={splash} splashIntroText={splash?.splashIntroText || tagline} css={`
      html {
        background: ${currentColours.backgroundColour};
        color: ${currentColours.textColour};
      }
      .cookie-notice {
        color: ${currentColours.textColour};
      }
    `}>
      {(setShowAbout, showIntro) => (
				<HomepageGallery
					projects={projects}
					showIntro={showIntro}
					setShowAbout={setShowAbout}
					setCurrentColours={setCurrentColours}
				/>
      )}
    </Layout>
  )
}

Homepage.propTypes = {
  data: PropTypes.object.isRequired
}

export default Homepage

export const HomepageQuery = graphql`
  query HomepageQuery {
    allContentfulHomepage (limit: 1) {
      nodes {
        title
				# Splash
				splash {
					## --- NEW SLIDES FIELD ---
					slides {
						...SlideAsset
					}
					## Large asset for > 768px
          asset {
            ...SlideAssetFragment
          }
					assets {
						...SlideAssetFragment
					}
					slideTimeDesktop
          ## Smaller portrait assets <= 768px
          assetMobile {
            ...SlideAssetFragment
          }
					assetsMobile {
						...SlideAssetFragment
					}
					slideTimeMobile
					## Colours
          colours {
            textColour
            backgroundColour
          }
					splashIntroText
				}
        # Projects
        projects {
					contentful_id
					## --- NEW SLIDES FIELD ---
					slides {
						...SlideAsset
					}
          ## Large asset for > 768px
          asset {
            ...SlideAssetFragment
          }
					assets {
						...SlideAssetFragment
					}
					slideTimeDesktop
          ## Smaller portrait assets <= 768px
          assetMobile {
            ...SlideAssetFragment
          }
					assetsMobile {
						...SlideAssetFragment
					}
					slideTimeMobile
          ## Project description copy
          description {
            childMarkdownRemark {
              html
            }
          }
          iframeLink
					iFrameLinkMobile
          ## Client
          client {
            name
          }
          ## Colours
          colours {
            textColour
            backgroundColour
          }
        }
      }
    }
  }
`

export const SlideAssetFragment = graphql`
	fragment SlideAssetFragment on ContentfulAsset {
		contentful_id
		file {
			contentType
			url
		}
		fluid(maxWidth: 1200, quality: 70) {
			...GatsbyContentfulFluid
		}
		gatsbyImageData(
			layout: FULL_WIDTH
			placeholder: DOMINANT_COLOR
			quality: 70
		)
	}
`
